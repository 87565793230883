import React from "react";
import { Link } from "gatsby";

import Img from "gatsby-image";

const PostSnippetCardWithImage = ({ title, excerpt, image, slug }) => {
  return (
    <div className="card border-0 bg-transparent font-cr">
      <Link to={slug}>
        <h4 className="text-truncate card-title text-dark text-uppercase font-cb">
          {title}
        </h4>
      </Link>
      <div
        style={{ borderTop: "1px solid #e41b13" }}
        className="pb-3 w-25"
      ></div>
      <Link to={slug}>
        <Img fluid={image.childImageSharp.fluid} />
      </Link>
      <div className="flex-column d-flex">
        <div className="pt-2">
          <div className="font-cr card-text text-truncate text-wrap text-dark text-line-height">
            {excerpt}
          </div>
          <Link to={slug} className="font-cb laas-text-dr">
            Read more...
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PostSnippetCardWithImage;
