import React from "react";
import { graphql, Link } from "gatsby";

import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Img from "gatsby-image";

import PostSnippetCardWithImage from "../components/PostSnippetCardWithImage";
import PostSnippetCardTopImage from "../components/PostSnippetCardTopImage";
import ThreeLinkBlock from "../components/ThreeLinkBlock";
import Layout from "../components/Layout";
// import BlogRoll from '../components/BlogRoll'

export const IndexPageTemplate = ({
  image,
  intro,
  testimony,
  report,
  news,
  shopImage
}) => (
  <>
    <Row className="py-3">
      <Col className="col-12 col-sm-10 offset-sm-1 px-0">
        <PreviewCompatibleImage
          imageInfo={image}
          style={{ maxHeight: "550px" }}
        />
        <Link to={news[0].fields.slug}>
          <div className="bg-dark font-cr text-white d-flex">
            <div style={{ flexBasis: "110px" }}>
              <span className="font-cb px-2" style={{ lineHeight: "2rem" }}>
                <i className="fas fa-caret-right laas-text-br"></i> LATEST
              </span>
            </div>
            <div className="ticker-tape-box">
              <span className="ticker-tape-text">{news[0].longExcerpt}</span>
            </div>
          </div>
        </Link>
      </Col>
      <Col className="col-12 col-sm-10 offset-sm-1 pt-5 py-3">
        <p className="text-line-height font-cr">{intro}</p>
      </Col>
    </Row>
    <div className="laas-logo-bg">
      <Row className="mx-3 py-5">
        <Col className="col-12 col-sm-6 col-md-6 offset-md-0 col-lg-5 offset-lg-1 pb-4">
          <div className="text-white">
            <i className="fas fa-caret-down"></i>
          </div>
          <h2 className="text-white font-cb">LATEST REPORTS</h2>
          <div className="pb-md-5">
            <PostSnippetCardWithImage
              image={report.frontmatter.image}
              title={report.frontmatter.title}
              excerpt={report.excerpt}
              slug={report.fields.slug}
            />
          </div>
        </Col>
        <Col className="col-12 col-sm-6 col-md-6 offset-md-0 col-lg-5 pb-5">
          <div className="text-white">
            <i className="fas fa-caret-down"></i>
          </div>
          <h2 className="text-white font-cb">TESTIMONY</h2>
          <div className="pb-md-5">
            <PostSnippetCardWithImage
              image={testimony.frontmatter.image}
              title={testimony.frontmatter.title}
              excerpt={testimony.excerpt}
              slug={testimony.fields.slug}
            />
          </div>
        </Col>
      </Row>
    </div>
    <Row className="py-5 laas-lighter-grey">
      <div className="w-100 pl-3 pt-3 laas-text-dr">
        <div>
          <i className="fas fa-caret-down"></i>
        </div>
        <h3 className="laas-text-dr font-cb">NEWS & COMMENT</h3>
      </div>
      {news.map(item => (
        <Col className="col-12 col-sm-4 py-5" key={item.id}>
          <PostSnippetCardTopImage
            title={item.frontmatter.title}
            excerpt={item.excerpt}
            slug={item.fields.slug}
            image={item.frontmatter.image}
          />
        </Col>
      ))}
    </Row>
    <ThreeLinkBlock />
    <Row className="py-5 laas-lighter-grey">
      <Col className="col-12 col-sm-6 offset-sm-3 pb-5">
        <div className="w-100 laas-text-dr">
          <div>
            <i className="fas fa-caret-down"></i>
          </div>
          <h3 className="laas-text-dr font-cb">SHOP</h3>
        </div>
        <div className="card border-0 bg-transparent">
          <h5 className="card-title text-dark text-uppercase font-cb">
            Take a look at LAAS merchandise
          </h5>
          <div
            className="text-white pb-3 w-25"
            style={{ borderTop: "1px solid #e41b13" }}
          ></div>
          <div className="flex-column flex-md-row d-flex">
            <div className="d-md-none">
              <a
                href="http://stores.clothes2order.com/laaswear/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img fluid={shopImage.childImageSharp.fluid} />
              </a>
            </div>
            <div className="d-none d-md-block">
              <a
                href="http://stores.clothes2order.com/laaswear/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img fixed={shopImage.childImageSharp.fixed} />
              </a>
            </div>
            <div className="pl-0 pl-md-2 pt-2 pt-md-0">
              <div className="card-text text-truncate text-wrap text-dark font-cr">
                LAAS have a range of merchandise, including insults thrown at us
                but senior Labour figures and racists. Because sometimes if you
                don't laugh, you cry.
              </div>
              <a
                href="http://stores.clothes2order.com/laaswear/"
                target="_blank"
                className="laas-text-br"
                rel="noopener noreferrer"
              >
                See more
              </a>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  </>
);

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.heroImage}
        shopImage={frontmatter.shopImage}
        title={frontmatter.title}
        intro={frontmatter.intro}
        overlay={frontmatter.overlay}
        testimony={data.testimony.nodes[0]}
        report={data.reports.nodes[0]}
        news={data.news.nodes}
        background={data.background}
      />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    background: file(relativePath: { eq: "LAASbg.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        heroImage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        intro
        overlay
        shopImage {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
            fixed(width: 150, height: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
    news: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "news-post" } } }
      limit: 3
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        id
        excerpt(pruneLength: 250)
        longExcerpt: excerpt(pruneLength: 500)
        fields {
          slug
        }
      }
    }
    testimony: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "testimony-post" } } }
      limit: 1
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
              fixed(width: 150, height: 200) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        id
        excerpt(pruneLength: 250)
        fields {
          slug
        }
      }
    }
    reports: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "reports-post" } } }
      limit: 1
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
              fixed(width: 150, height: 200) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        id
        excerpt(pruneLength: 250)
        fields {
          slug
        }
      }
    }
  }
`;
