import React from "react";
import { Link } from "gatsby";

import Img from "gatsby-image";

const PostSnippetCardWithImage = ({ title, excerpt, image, slug }) => {
  return (
    <div className="card border-0 bg-transparent font-cr">
      <Link to={slug}>
        <h5 className="card-title text-dark text-uppercase font-cb">{title}</h5>
      </Link>
      <div className="text-white pb-3 w-25 border-top"></div>
      <div className="flex-column flex-md-row d-flex">
        <div className="d-md-none">
          <Link to={slug}>
            <Img fluid={image.childImageSharp.fluid} />
          </Link>
        </div>
        <div className="d-none d-md-block">
          <Link to={slug}>
            <Img fixed={image.childImageSharp.fixed} />
          </Link>
        </div>
        <div className="pl-0 pl-md-2 pt-2 pt-md-0">
          <div className="font-cr card-text text-truncate text-wrap text-white text-line-height">
            {excerpt}
          </div>
          <Link to={slug} className="font-cb text-white">
            Read more...
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PostSnippetCardWithImage;
